import axios from "axios";
import { config, getAxiosHeaders } from "./ApiConfig";
import getErrorMessage from "../helpers/getErrorMessage";
import { loginModel } from "../models/authModel";

export const login = async ({ username, password, captchaToken, geeTest }) => {
  try {
    const data = {
      username,
      password,
    };
    if (geeTest) {
      data.geeTest = geeTest;
    }
    if (captchaToken) {
      data.captchaToken = captchaToken;
    }

    const res = await axios.post(
      `${config.APP_URL_V4}/auth/login`,
      data,
      getAxiosHeaders()
    );

    return loginModel(res.data);
  } catch (e) {
    if (e.response.data.code === 1002) {
      return { recaptchaAssessmentId: e.response.data.uuid };
    }
    return getErrorMessage(e);
  }
};

export const signUp = async ({ email, password, captchaToken, geeTest }) => {
  try {
    const data = { email, password };
    if (geeTest) {
      data.geeTest = geeTest;
    }
    if (captchaToken) {
      data.captchaToken = captchaToken;
    }
    const res = await axios.post(
      `${config.APP_URL_V4}/auth/signup`,
      data,
      getAxiosHeaders()
    );

    return { token: res.data.sessionToken };
  } catch (e) {
    if (e.response.data.code === 1002) {
      return { recaptchaAssessmentId: e.response.data.uuid };
    }
    return getErrorMessage(e);
  }
};

export const logOut = async () => {
  try {
    await axios.post(`${config.APP_URL_V4}/auth/logout`, {}, getAxiosHeaders());
  } catch (e) {}
};

export const me = async ({ token, signal }) => {
  const headers = {};
  if (token) {
    headers.token = token;
  }

  try {
    const { data } = await axios.get(config.APP_URL_V2 + "/me", {
      ...getAxiosHeaders(headers),
      signal,
    });

    return data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const sendEmailVerification = async () => {
  try {
    const res = await axios.post(
      `${config.APP_URL_V2}/user/profile/send/email`,
      {},
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  try {
    const res = await axios.post(
      `${config.APP_URL_V2}/user/profile/changePassword`,
      { oldPassword, newPassword },
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const verifyEmail = async (token) => {
  try {
    return axios.post(`${config.APP_URL_V2}/email/verify`, { token });
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const tfaVerification = async (userToken, tfaSecret) => {
  try {
    const { data } = await axios.post(
      `${config.APP_URL_V4}/auth/2fa/verification`,
      {
        userToken,
        tfaSecret,
      },
      getAxiosHeaders()
    );

    return loginModel(data);
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const getUserSubscriptionData = async (token) => {
  try {
    const { data } = await axios.get(
      config.APP_URL_V3 + "/subscriptions/public_api",
      getAxiosHeaders(token ? { token } : {})
    );

    return data;
  } catch (e) {
    return getErrorMessage(e);
  }
};
