import { useEffect } from "react";
import { API_URL, config } from "../api/ApiConfig";
import { getSubDomain } from "../helpers/getSubDomain";

function removeHttp(url) {
  return url.replace(/^https?:\/\//, "");
}
function getKey() {
  const subdomain = getSubDomain(removeHttp(API_URL)) || "";
  return subdomain === "api"
    ? config.googleCaptchaSiteKey
    : config.googleCaptchaSiteKeyTest;
}
function getExistScript() {
  return document.getElementById("recaptcha_script");
}

function createScript() {
  let script = getExistScript();
  if (!script) {
    const key = getKey();
    script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${key}`;
    script.defer = true;
    script.async = true;
    script.id = "recaptcha_script";
  }
  script.addEventListener("load", () => {
    window.grecaptcha.enterprise.ready(() => {
      Grecaptcha = window.grecaptcha.enterprise;
    });
  });
  document.body.appendChild(script);
}

let Grecaptcha = null;

export async function getGrecaptchaToken(action) {
  if (!getExistScript()) {
    return undefined;
  }
  const key = getKey();
  if (Grecaptcha) {
    return Grecaptcha.execute(key, { action });
  }
  return new Promise((resolve) => {
    const getToken = () => {
      window.grecaptcha.enterprise.ready(async () => {
        const token = await window.grecaptcha.enterprise.execute(key, {
          action,
        });
        resolve(token);
      });
    };
    if (!window.grecaptcha?.enterprise) {
      const interval = setInterval(() => {
        if (window.grecaptcha?.enterprise) {
          clearInterval(interval);
          getToken();
        }
      }, 1000);
    } else {
      getToken();
    }
  });
}

export function useRecaptchaToken() {
  useEffect(() => {
    if (Grecaptcha) {
      return;
    }
    createScript();
  }, []);
}
