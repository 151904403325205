import { Progress } from "antd";

const GradientProgressBar = ({
  percent = 0,
  showInfo = true,
  ...defaultProgressBarProps
}) => {
  return (
    <Progress
      {...defaultProgressBarProps}
      percent={percent}
      showInfo={showInfo}
      strokeColor={{
        "0.3%": "#CF5CFF",
        "51.55%": "#FF6398",
        "99.7%": "#FF9D71",
      }}
    />
  );
};

export default GradientProgressBar;
