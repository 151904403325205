import { differenceInDays } from "date-fns";
import { chartIntervalOptions } from "../data/chartDefaultData";
import { CHART_INTERVALS } from "../enums/chartIntervals";

export function getChartConvertedOptions({ dates }) {
  const convertedIntervalOptions = chartIntervalOptions.map((interval) => {

    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[1]);
    const rangeLength = differenceInDays(endDate, startDate);

    if (interval.value === CHART_INTERVALS.hour && rangeLength >= 20) {
      interval.disabled = true;
      return interval;
    }

    if (interval.value === CHART_INTERVALS.day && rangeLength >= 91) {
      interval.disabled = true;
      return interval;
    }

    if (
      interval.value === CHART_INTERVALS.week &&
      (rangeLength < 6 || rangeLength > 365)
    ) {
      interval.disabled = true;
      return interval;
    }

    if (interval.value === CHART_INTERVALS.month && rangeLength >= 1825) {
      interval.disabled = true;
      return interval;
    }

    interval.disabled = false;
    return interval;
  });

  const firstActiveInterval = convertedIntervalOptions.find(
    (interval) => !interval.disabled
  );

  return { convertedIntervalOptions, firstActiveInterval };
}
