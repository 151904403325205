import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Modal, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  getGrecaptchaToken,
  useRecaptchaToken,
} from "../../../Hooks/useRecaptchaToken";
import { login, tfaVerification } from "../../../api/auth";
import { getUserData } from "../../../helpers/getUserData";
import GeeTestV4 from "../../GeeTestV4";
import RECAPTCHA_ACTIONS from "../../../enums/RecaptchaActions";

import CSLogoImg from "../../../assets/images/cs-logo.svg";

const { Title, Text } = Typography;

const SignIn = ({ handleAuthTypeClick, setUserData }) => {
  const [state, setState] = useState({
    username: "",
    password: "",
    errorMessage: "",
    recaptchaAssessmentId: "",
    tfaSecret: "",
    userToken: "",
    isLoading: false,
    isCaptchaPopupOpen: false,
    is2FAModalOpen: false,
    is2FALoading: false,
  });

  const [form] = useForm();
  const navigate = useNavigate();
  const isConfirmButtonDisabled =
    state.is2FALoading || state.tfaSecret.length === 0;
  useRecaptchaToken();

  const onCaptchaSuccess = async ({
    geeTest,
    recaptchaToken,
    authData,
  } = {}) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorMessage: "",
        isLoading: true,
      };
    });

    let reqBody = {
      username: state.username,
      password: state.password,
      geeTest: {
        ...geeTest,
        recaptchaAssessmentId: state.recaptchaAssessmentId,
      },
    };
    if (!geeTest) {
      reqBody.captchaToken = recaptchaToken;
    }
    if (authData) {
      reqBody.username = authData.username;
      reqBody.password = authData.password;
    }

    try {
      const loginData = await login(reqBody);

      if (loginData.tfaEnabled) {
        setState((prevState) => {
          return {
            ...prevState,
            is2FAModalOpen: true,
            userToken: loginData.userToken,
          };
        });
        return;
      }

      if (loginData.recaptchaAssessmentId) {
        setState((prevState) => {
          return {
            ...prevState,
            isCaptchaPopupOpen: true,
            recaptchaAssessmentId: loginData.recaptchaAssessmentId,
          };
        });
        return;
      }

      if (loginData.error) {
        setState((prevState) => {
          return {
            ...prevState,
            isCaptchaPopupOpen: false,
            isLoading: false,
            errorMessage: loginData.error,
          };
        });
      }

      if (loginData.sessionToken) {
        const userData = await getUserData(loginData.sessionToken);
        if (userData) {
          const userDataBase = btoa(loginData.sessionToken);
          localStorage.setItem("token", userDataBase);
          message.success("Form submitted successfully!");
          setUserData({ userData, isUserLoading: false });
          navigate("/");
        }

        setState((prevState) => {
          return { ...prevState, isLoading: false };
        });
      }

      setState((prevState) => {
        return { ...prevState, isLoading: false, isCaptchaPopupOpen: false };
      });
    } catch (err) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: "Invalid email or password",
          isCaptchaPopupOpen: false,
          isLoading: false,
        };
      });
    }
  };

  const onFormSubmit = async (authData) => {
    if (state.isLoading) {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
        errorMessage: "",
        username: authData.username,
        password: authData.password,
      };
    });
    localStorage.removeItem("token");

    const recaptchaToken = await getGrecaptchaToken(
      RECAPTCHA_ACTIONS.email_login
    );

    if (recaptchaToken) {
      await onCaptchaSuccess({ recaptchaToken, authData });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isCaptchaPopupOpen: true,
        };
      });
    }
  };

  const onCaptchaFail = () => {
    message.error("Captcha submission failed");
    setState((prevState) => {
      return {
        ...prevState,
        isCaptchaPopupOpen: false,
        isLoading: false,
        errorMessage: "",
      };
    });
  };

  const onSubmit2FAModal = async () => {
    if (isConfirmButtonDisabled) {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        is2FALoading: true,
        errorMessage: "",
      };
    });

    const loginData = await tfaVerification(state.userToken, state.tfaSecret);

    if (loginData.error) {
      setState((prevState) => {
        return {
          ...prevState,
          is2FALoading: false,
          errorMessage: loginData.error,
        };
      });
      return;
    }

    const userData = await getUserData(loginData.sessionToken);
    if (userData) {
      const userDataBase = btoa(loginData.sessionToken);
      localStorage.setItem("token", userDataBase);
      message.success("Form submitted successfully!");
      setUserData({ userData, isUserLoading: false });
    }

    setState((prevState) => {
      return {
        ...prevState,
        is2FAModalOpen: false,
        is2FALoading: false,
      };
    });
  };

  const handle2FAInputChange = (e) => {
    const tfaSecret = e.target.value.trim();

    setState((prevState) => {
      return {
        ...prevState,
        tfaSecret,
        errorMessage: "",
      };
    });
  };

  const handleModalClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        is2FAModalOpen: false,
        isLoading: false,
        tfaSecret: "",
        userToken: "",
      };
    });
  };

  return (
    <div>
      {state.errorMessage && message.error(state.errorMessage)}
      {state.isCaptchaPopupOpen && (
        <GeeTestV4
          forceShow={state.isCaptchaPopupOpen}
          onError={onCaptchaFail}
          onSuccess={onCaptchaSuccess}
          onClose={onCaptchaFail}
          isDestroy={state.errorMessage}
        />
      )}
      {state.is2FAModalOpen && (
        <Modal
          title="2 Factor Authentication"
          footer={null}
          open={state.is2FAModalOpen}
          onCancel={handleModalClose}
          keyboard
          maskClosable
          centered
        >
          <Form name="2FAModal" form={form}>
            <Text type="secondary" className="description">
              Fill in your 2FA code to continue
            </Text>
            <Form.Item name="tfaSecret">
              <Input
                placeholder="Verification Code"
                onChange={handle2FAInputChange}
                onPressEnter={onSubmit2FAModal}
                status={state.errorMessage && "error"}
                allowClear
              />
            </Form.Item>
            <Button
              type="primary"
              className="confirm-btn"
              loading={state.is2FALoading}
              onClick={onSubmit2FAModal}
              disabled={isConfirmButtonDisabled}
              block
            >
              Confirm
            </Button>
          </Form>
        </Modal>
      )}
      <img src={CSLogoImg} alt="coinstats logo" />
      <Title level={2} className="title">
        Login
      </Title>
      <Text type="secondary" className="description">
        Enter Your credential to login into your account.
      </Text>
      <Form name="login" className="form" form={form} onFinish={onFormSubmit}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Email"
            autoComplete="on"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            autoComplete="on"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="form-button"
            loading={state.isLoading}
          >
            Log In
          </Button>
          <div className="registration-section">
            <Text type="secondary" className="secondary-text">
              Don't have an account?
            </Text>
            <a onClick={handleAuthTypeClick}>Create Account</a>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default SignIn;
