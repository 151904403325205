import axios from "axios";
import { config, getAxiosHeaders } from "./ApiConfig";
import getErrorMessage from "../helpers/getErrorMessage";

export const generateApiKey = async () => {
  try {
    const res = await axios.post(
      config.APP_URL_V2 + "/user/public_api/key",
      {},
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const getApiKey = async () => {
  try {
    const res = await axios.get(
      config.APP_URL_V2 + "/user/public_api/key",
      getAxiosHeaders()
    );

    return res.data.apiKey;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const deleteApiKey = async () => {
  try {
    const res = await axios.delete(
      config.APP_URL_V2 + "/user/public_api/key",
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const getLimit = async () => {
  try {
    const res = await axios.get(
      config.APP_URL_V3 + "/subscriptions/public_api/products",
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};
