import { format } from "date-fns";

export function getLastWeekDates() {
  const weekStartDate = new Date();
  const weekEndDate = new Date();

  weekStartDate.setDate(weekStartDate.getDate() - 7);
  return {
    formattedWeekStartDate: format(weekStartDate, "yyyy-MM-dd"),
    formattedWeekEndDate: format(weekEndDate, "yyyy-MM-dd"),
    isoFormattedWeekStartDate: weekStartDate.toISOString(),
    isoFormattedWeekEndDate: weekEndDate.toISOString(),
  };
}
