import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Result, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { sendEmailVerification } from "../../../api/auth";
import { doesUserTokenExist } from "../../../helpers/doesUserTokenExist";

const EmailVerification = ({ setUserData, userData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (doesUserTokenExist()) {
      if (userData && userData.emailVerified) {
        navigate("/");
      }
      return;
    }

    navigate("/login");
  }, [navigate, setUserData, userData]);

  const resendHandler = async () => {
    setIsLoading(true);
    const result = await sendEmailVerification();
    setIsLoading(false);

    if (result.error) {
      message.error(result.error);
      return;
    }

    message.success(
      "Please check your inbox. We just sent your verification email."
    );
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login/");
  };

  return (
    <Result
      icon={<MailOutlined />}
      title="Please Verify Your Email to Proceed"
      subTitle="Please check your email for the verification link."
      extra={[
        <Button
          key="resend"
          type="primary"
          onClick={resendHandler}
          loading={isLoading}
        >
          Resend Verification E-mail
        </Button>,
        <Button key="logout" onClick={logoutHandler}>
          Logout
        </Button>,
      ]}
    />
  );
};
export default EmailVerification;
