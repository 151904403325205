import { useState } from "react";
import { Button, Form, Input, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { changePassword } from "../../../api/auth";
import "./index.scss";

const { Title } = Typography;

const ChangePasswordSection = ({ handleChangePassword }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = useForm();

  const onFormSubmit = async (authData) => {
    setIsSubmitting(true);

    const res = await changePassword(authData);

    setIsSubmitting(false);

    if (res?.error) {
      message.error(res.error);
    }

    if (res?.success) {
      message.success("Your password was changed");
    }
  };

  return (
    <div className="change-password-section">
      <Title level={2} className="section-name">
        Change Password
      </Title>
      <Form name="update" className="form" form={form} onFinish={onFormSubmit}>
        <Form.Item
          name="oldPassword"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            autoComplete="on"
            placeholder="Enter old password"
          />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            autoComplete="on"
            placeholder="Setup new password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={handleChangePassword}
            className="change-password-section_cancel-btn"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="change-password-section_update-btn"
            loading={isSubmitting}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default ChangePasswordSection;
