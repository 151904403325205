import { AreaChartOutlined, HistoryOutlined } from "@ant-design/icons";

export const menuData = [
  {
    label: "History Chart",
    key: "chart",
    icon: <AreaChartOutlined />,
  },
  {
    label: "History Table",
    key: "",
    icon: <HistoryOutlined />,
  },
];
