export const loginModel = (loginData = {}) => {
  if (loginData.tfaEnabled) {
    return {
      tfaEnabled: true,
      userToken: loginData.userToken,
      isNew: loginData.isNew,
    };
  }

  return {
    hasUnlimitedAccess: !!loginData.hasUnlimitedAccess,
    isVerified: !!loginData.isVerified,
    sessionToken: loginData.sessionToken || "",
    isNew: loginData.isNew,
  };
};
