import React from "react";

import HistorySection from "./HistorySection";
import DashboardSection from "./DashboardSection";
import AccountSection from "../AccountSection";
import PricingSection from "./PricingSection";

import "./index.scss";
import { Route, Routes,  } from "react-router-dom";
import ErrorPage from "../ErrorPage";

const Dashboard = ({ userData }) => {

  return (
    <Routes>
        <Route path="" element={<DashboardSection user={userData} />} />
        <Route path="history" element={<HistorySection />} />
        <Route path="pricing" element={<PricingSection user={userData} />} />
        <Route path="profile" element={<AccountSection user={userData} />} />
        <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Dashboard;
