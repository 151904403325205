import { message } from "antd";
import { getUserSubscriptionData, me } from "../api/auth";

export const getUserData = async (token) => {
  try {
    const res = await me({ token });

    if (res.error) {
      message.error(res.error);
      return null;
    }

    const subscriptionData = await getUserSubscriptionData(token);
    if (subscriptionData.subscription) {
      res.subscription = subscriptionData.subscription;
    }
    return res;
  } catch (err) {
    return null;
  }
};
