import { useState, useEffect, useMemo } from "react";
import { requestHistoryChart } from "../../../../api/requestHistory";
import { DatePicker, Divider, Empty, message, Select, Spin } from "antd";
import { Column } from "@ant-design/plots";
import dayjs from "dayjs";
import "dayjs/locale/en";

import {
  chartIntervalOptions,
  statusSelectOptions,
} from "../../../../data/chartDefaultData";
import { STATUS } from "../../../../enums/status";
import { CHART_CONFIG } from "../../../../enums/chartConfig";
import { generateDateRangeByInterval } from "../../../../helpers/generateDateRangeByInterval";
import { getLastWeekDates } from "../../../../helpers/getLastWeekDates";
import { getUserApiKey } from "../../../../helpers/getUserApiKey";
import { generateHourlyDateRange } from "../../../../helpers/generateHourlyDateRange";
import { getChartConvertedOptions } from "../../../../helpers/getChartConvertedOptions";

const { RangePicker } = DatePicker;
const { Option } = Select;
dayjs.locale("en");

const HistoryChart = () => {
  const {
    formattedWeekStartDate,
    formattedWeekEndDate,
    isoFormattedWeekStartDate,
    isoFormattedWeekEndDate,
  } = getLastWeekDates();

  const [state, setState] = useState({
    fetchedData: [],
    status: STATUS.success,
    intervals: chartIntervalOptions,
    activeInterval: chartIntervalOptions[0],
    selectedDate: [formattedWeekStartDate, formattedWeekEndDate],
    selectedIsoDate: [isoFormattedWeekStartDate, isoFormattedWeekEndDate],
  });

  useEffect(() => {
    if (state.selectedDate.length === 0 || !getUserApiKey()) {
      return;
    }

    const abortController = new AbortController();
    getChartData();

    async function getChartData() {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      try {
        const res = await requestHistoryChart({
          from: state.selectedIsoDate[0],
          to: state.selectedIsoDate[1],
          status: state.status,
          interval: state.activeInterval.value,
          signal: abortController.signal,
        });

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));

        if (abortController.signal.aborted) {
          return;
        }

        if (res.error) {
          message.error(res.error);
          return;
        }

        setState((prevState) => {
          return {
            ...prevState,
            fetchedData: res,
          };
        });
      } catch (error) {
        message.error(error.message);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }

    return () => {
      abortController.abort();
    };
  }, [
    state.selectedIsoDate,
    state.activeInterval.value,
    state.selectedDate.length,
    state.status,
  ]);

  const handleIntervalChange = (range) => {
    setState((prevState) => ({
      ...prevState,
      activeInterval: range,
    }));
  };

  const handleDateChange = (_, dates) => {
    if (dates[0] === "" && dates[1] === "") {
      setState((prevState) => ({
        ...prevState,
        fetchedData: [],
        selectedDate: [],
        selectedIsoDate: [],
      }));

      return;
    }

    const convertedDate = dates.map((item) => new Date(item).toISOString());

    setState((prevState) => {
      const { convertedIntervalOptions, firstActiveInterval } =
        getChartConvertedOptions({ dates });

      return {
        ...prevState,
        intervals: convertedIntervalOptions,
        activeInterval: firstActiveInterval,
        selectedIsoDate: convertedDate,
        selectedDate: dates,
      };
    });
  };

  const handleStatusChange = (selectedStatus) => {
    setState((prevState) => ({
      ...prevState,
      status: selectedStatus.value,
    }));
  };

  const convertedChartConfig = useMemo(() => {
    if (state.selectedDate.length === 0 || state.fetchedData?.length === 0) {
      return [];
    }

    let convertedChartData;

    switch (state.activeInterval.value) {
      case "1h":
        convertedChartData = generateHourlyDateRange(
          state.selectedDate[0],
          state.selectedDate[1],
          state.fetchedData
        );
        break;
      default:
        convertedChartData = generateDateRangeByInterval(
          state.selectedDate[0],
          state.selectedDate[1],
          state.fetchedData,
          state.activeInterval.value
        );
    }

    return {
      data: convertedChartData,
      ...CHART_CONFIG,
    };
  }, [state.activeInterval, state.selectedDate, state.fetchedData]);

  const isChartVisible = state.fetchedData.length > 0;
  const isEmptyCaseVisible = !isChartVisible && !state.isLoading;
  const defaultRangePickerDate = [
    dayjs(formattedWeekStartDate),
    dayjs(formattedWeekEndDate),
  ];

  return (
    <div className="chart-section">
      <div className="chart-action-buttons">
        <RangePicker
          size="middle"
          onChange={handleDateChange}
          defaultValue={defaultRangePickerDate}
        />
        <Select
          labelInValue
          defaultValue={state.status}
          options={statusSelectOptions}
          onChange={handleStatusChange}
          style={{ width: 100 }}
        />
        <Select
          labelInValue
          value={state.activeInterval}
          onChange={handleIntervalChange}
          style={{ width: 100 }}
        >
          {state.intervals.map((option) => (
            <Option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <Divider />
      {isChartVisible && <Column {...convertedChartConfig} />}
      {isEmptyCaseVisible && <Empty />}
      {state.isLoading && <Spin />}
    </div>
  );
};

export default HistoryChart;
