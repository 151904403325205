import { useEffect, useState } from "react";
import { Pagination, Table, message } from "antd";
import { requestHistoryTable } from "../../../../api/requestHistory";
import { getUserApiKey } from "../../../../helpers/getUserApiKey";
import { tableHeadData } from "../../../../data/tableData";

const HistoryTable = () => {
  const [state, setState] = useState({
    meta: {},
    result: [],
    currentPage: 1,
    isLoading: false,
  });

  useEffect(() => {
    if (!getUserApiKey()) {
      return;
    }

    getTableData();

    async function getTableData() {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      try {
        const res = await requestHistoryTable(state.currentPage);
        if (!res.result) {
          throw Error(res.error);
        }
        setState((prevState) => ({
          ...prevState,
          meta: res.meta,
          result: res.result,
          isLoading: false,
        }));
      } catch (error) {
        message.error(error.message);
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }
  }, [state.currentPage]);

  const convertedTableData = (fetchedData) => {
    if (fetchedData?.length === 0) {
      return [];
    }

    let convertedData = fetchedData.map((item, index) => {
      const convertedIsoDate = new Date(item.createdAt).toLocaleDateString();

      return {
        key: index,
        requestPath: item.requestPath || "-",
        status: item.status || "",
        statusCode: item.statusCode || "-",
        date: convertedIsoDate || "-",
      };
    });

    return convertedData || [];
  };

  const handlePageChange = (currentPage) => {
    setState((prevState) => ({
      ...prevState,
      currentPage,
    }));
  };

  const enableVerticalTableScroll = { x: "calc(100vw - 300px)" };
  return (
    <>
      <Table
        columns={tableHeadData}
        dataSource={convertedTableData(state.result) || []}
        scroll={enableVerticalTableScroll}
        loading={state.isLoading}
        pagination={false}
      />
      <Pagination
        onChange={handlePageChange}
        className="history-pagination"
        current={state.currentPage}
        total={state.meta?.itemCount}
        pageSize={state.meta?.limit || 20}
        showSizeChanger={false}
      />
    </>
  );
};
export default HistoryTable;
