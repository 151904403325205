import React, { useEffect, useState } from "react";
import { Avatar, Button, Typography, message } from "antd";
import getUserSubscriptionPlan from "../../helpers/getUserSubscriptionPlan";
import { getManagementUrl, getPriceList } from "../../api/pricing";
import ChangePasswordSection from "./ChangePasswordSection";
import Footer from "../Footer";

import "./index.scss";
import DefaultAvatarImg from "../../assets/images/avatar.svg";
import { Link } from "react-router-dom";
import { WarningFilled } from "@ant-design/icons";
import { sendEmailVerification } from "../../api/auth";

const { Title } = Typography;

const AccountSection = ({ user }) => {
  const [isChangePasswordSection, setIsChangePasswordSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({
    premium: {},
    individual: {},
  });

  async function getStripePricesList(subsciptionPlan) {
    const res = await getPriceList();
    if (subsciptionPlan === res.premium.monthly.id) {
      setCurrentPlan({ premium: res.premium });
    }
    if (subsciptionPlan === res.standard.monthly.id) {
      setCurrentPlan({ individual: res.standard });
    }
  }

  useEffect(() => {
    const subsciptionPlan = getUserSubscriptionPlan(user);

    if (!subsciptionPlan) {
      return;
    }

    getStripePricesList(subsciptionPlan);
  }, [user]);

  const handleChangePassword = () => {
    setIsChangePasswordSection((prevState) => !prevState);
  };

  const manageClickHandler = async () => {
    const res = await getManagementUrl();
    if (!res.url) {
      return;
    }
    window.location.href = res.url;
  };

  const resendHandler = async () => {
    setIsLoading(true);
    const result = await sendEmailVerification();
    setIsLoading(false);

    if (result.error) {
      message.error(result.error);
      return;
    }

    message.success(
      "Please check your inbox. We just sent your verification email."
    );
  };

  const currentPlanName = Object.keys(currentPlan)[0];
  const currentPlanPrice = currentPlan[currentPlanName].monthly?.price;
  const isFreePlan = !currentPlanPrice;
  return (
    <>
      {!isChangePasswordSection && (
        <>
          <Title level={2} className="section-name">
            Account
          </Title>
          <div className="account-section">
            <Avatar src={user.imageUrl ? user.imageUrl : DefaultAvatarImg} />
            <div>
              <p>
                Email: <span>{user.email}</span>
              </p>
              <p>
                Password:<span>·············</span>
                <Button onClick={handleChangePassword}>Change</Button>
              </p>
              <br />
              {user.emailStatus === "VERIFICATION_EMAIL_SENT" && (
                <div>
                  <WarningFilled style={{ color: "orange" }} /> Verification
                  Email Sent
                </div>
              )}
              {!["EMAIL_VERIFIED", "VERIFICATION_EMAIL_SENT"].includes(
                user.emailStatus
              ) && (
                  <p>
                    <WarningFilled style={{ color: "red" }} />
                    Email is Not Verified
                    <Button
                      type="primary"
                      disabled={isLoading}
                      loading={isLoading}
                      onClick={resendHandler}
                    >
                      Resend Verfication email
                    </Button>
                  </p>
                )}
            </div>
          </div>
          <div className="account-plan">
            <p className="account-plan_title">Current plan</p>
            {currentPlanPrice && (
              <p className="account-plan_description">
                <span>{currentPlanName}</span> ${currentPlanPrice}/month
              </p>
            )}
            {isFreePlan && (
              <p className="account-plan_description">
                <span>Free</span> $0/month
              </p>
            )}
            {
              !isFreePlan && <Button onClick={manageClickHandler}>Manage</Button>
            }
            <Button className="upgrade-button">
              <Link to="/pricing">Upgrade</Link>
            </Button>
          </div>
        </>
      )}
      {isChangePasswordSection && (
        <ChangePasswordSection handleChangePassword={handleChangePassword} />
      )}
      <Footer />
    </>
  );
};

export default AccountSection;
