import { STATUS } from "../enums/status";
import { CHART_INTERVALS } from "../enums/chartIntervals";

export const chartIntervalOptions = [
  {
    label: "1 Hour",
    value: CHART_INTERVALS.hour,
  },
  {
    label: "1 Day",
    value: CHART_INTERVALS.day,
  },
  {
    label: "1 Week",
    value: CHART_INTERVALS.week,
  },
  {
    label: "1 Month",
    value: CHART_INTERVALS.month,
  },
  {
    label: "1 Year",
    value: CHART_INTERVALS.year,
  },
];

export const statusSelectOptions = [
  {
    value: STATUS.success,
  },
  {
    value: STATUS.fail,
  },
];
