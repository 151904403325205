import { Tag } from "antd";
import { STATUS } from "../enums/status";

export const tableHeadData = [
  {
    title: "Request Path",
    dataIndex: "requestPath",
    key: "requestPath",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: getTagStatus,
  },
  {
    title: "Status Code",
    dataIndex: "statusCode",
    key: "statusCode",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
];

function getTagStatus(status) {
  let tagColor = "green";

  if (status === STATUS.fail) {
    tagColor = "red";
  }

  return <Tag color={tagColor}>{status}</Tag>;
}
