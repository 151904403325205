import {
  compareAsc,
  eachHourOfInterval,
  format,
  parseISO,
  startOfDay,
} from "date-fns";

/**
 * generateHourlyDateRange Helper
 * @description Function generates a sorted range of dates between a given start and end date, by one hour interval.
 *
 * @param {string} startDate - start date of selected Range
 * @param {string} endDate - end date of selected Range
 * @param {array} fetchedDates - fetched Dates range between startDate and endDate
 *
 * @returns { [{date: string, count: number}] }
 */

export function generateHourlyDateRange(startDate, endDate, fetchedDates) {
  const dateRange = [];

  fetchedDates.forEach((date) => {
    const replacedDate = date.date.replace(/-(?=[^-\s]*$)/, ":");
    dateRange.push({ date: replacedDate, count: date.count });
  });

  let currentDate = startOfDay(new Date(startDate));
  let lastDate = startOfDay(new Date(endDate));
  const rangeOfHours = eachHourOfInterval({
    start: currentDate,
    end: lastDate,
  });

  for (const hour of rangeOfHours) {
    const formattedHour = format(hour, "yyyy-MM-dd HH:mm");

    if (!dateRange.find((item) => item.date === formattedHour)) {
      dateRange.push({ date: formattedHour, count: 0 });
    }
  }

  return dateRange.sort((a, b) =>
    compareAsc(parseISO(a.date), parseISO(b.date))
  );
}
