import axios from "axios";
import { config, getAxiosHeaders } from "./ApiConfig";
import getErrorMessage from "../helpers/getErrorMessage";

export const requestHistoryChart = async ({
  from,
  to,
  status,
  interval,
  signal,
}) => {
  try {
    const res = await axios.get(
      `${config.APP_URL_V2}/user/public_api/request-history/charts`,
      {
        ...getAxiosHeaders(),
        signal,
        params: {
          from,
          to,
          status,
          interval,
        },
      }
    );

    return res.data.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};

export const requestHistoryTable = async (page) => {
  try {
    const dataToSend = {
      ...getAxiosHeaders(),
      params: {
        page,
      },
    };

    const res = await axios.get(
      `${config.APP_URL_V2}/user/public_api/request-history`,
      dataToSend
    );

    return res.data;
  } catch (e) {
    return getErrorMessage(e);
  }
};
