import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { logOut } from "../../../api/auth";
import { clearUserStorageData } from "../../../helpers/clearUserStorageData";

const SideBarMenu = ({
  resetUserData,
}) => {
  const navigate = useNavigate();

  const logOutHandler = async () => {
    await logOut();
    clearUserStorageData();
    resetUserData();

    navigate("/login/");
  };

  return (
    <div className="sidebar-menu">
      <Menu mode="inline">
        <Menu.Item>
          <Link to="/">
            <i className="icon-dashboard-icon" />
            Dashboard
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/history">
            <i className="icon-history-icon" />
            Usage History
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/pricing">
            <i className="icon-pricing-icon" />
            Pricing
          </Link>
        </Menu.Item>
        <Menu.Item key="apiDocLink">
          <Link
            to="https://docs.api.coinstats.app/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="icon-api-doc-icon" />
            API Docs
          </Link>
        </Menu.Item>
        <Menu.Item
          key="logout"
          className="logout-submenu"
          onClick={logOutHandler}
        >
          <LogoutOutlined />
          Log Out
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default SideBarMenu;
