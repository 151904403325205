import { memo, useEffect } from "react";
import { API_URL, ApiUrls } from "../../api/ApiConfig";

/**
 * GeeTestV4 Component
 * @description This is GTest captcha.
 * @param {object} prop
 * @param {object} prop.buttonRef
 * @param {CallableFunction} prop.onSuccess
 * @param {CallableFunction} prop.onError
 * @param {CallableFunction} prop.onClose
 * @param {CallableFunction} prop.onReady
 * @param {CallableFunction} prop.onFail
 * @param {boolean} prop.isDestroy
 * @param {boolean} prop.forceShow
 * @param {boolean} prop.forceReset
 * @returns {null}
 */
const GeeTestV4 = ({
  buttonRef = {},
  onSuccess = Function.prototype,
  onError = Function.prototype,
  onClose = Function.prototype,
  onReady = Function.prototype,
  onFail = Function.prototype,
  isDestroy = false,
  forceShow = false, //todo delete
  forceReset = false,
}) => {
  const init = async () => {
    if (buttonRef?.current || forceShow) {
      const initGeetest4 = window.initGeetest4;
      const isProdMode = [ApiUrls.prod, ApiUrls.staging].includes(API_URL);
      initGeetest4(
        {
          lang: "en",
          product: "bind",
          https: true,
          hideRefresh: true,
          hideSuccess: true,
          hideError: true,
          captchaId: isProdMode
            ? "589b495e8f5d99ad8463f5faa1b0c4b1"
            : "0e717c0d36ee1aefd31f514e9809a565",
          outside: true,
        },
        (captcha) => {
          buttonRef?.current?.addEventListener("click", () =>
            captcha.showBox()
          );

          if (forceShow) {
            captcha.showBox();
          }
          if (isDestroy) {
            captcha.destroy();
          }
          if (forceReset) {
            captcha.reset();
          }

          captcha
            .onReady(() => {
              onReady();
            })
            .onSuccess(() => {
              const { lot_number, captcha_output, pass_token, gen_time } =
                captcha.getValidate();
              onSuccess({
                geeTest: { lot_number, captcha_output, pass_token, gen_time },
              });
            })
            .onError((e) => {
              console.log(e);
              onError();
            })
            .onFail((e) => {
              console.log(e);
              onFail();
            })
            .onClose(() => {
              onClose();
            });
        }
      );
    }
    return () => buttonRef?.current?.removeEventListener("click");
  };

  useEffect(() => {
    init();
  }, [forceShow, forceReset, isDestroy]);

  return null;
};

export default memo(GeeTestV4);
