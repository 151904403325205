import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { logOut } from "../../../api/auth";
import { clearUserStorageData } from "../../../helpers/clearUserStorageData";

import "./index.scss";

import DefaultAvatarImg from "../../../assets/images/avatar.svg";
import CSLogoImg from "../../../assets/images/cs-logo.svg";

const Header = ({
  user,
  isMenuOpen,
  menuOpenHandler,
  resetUserData,
  children,
}) => {
  const navigate = useNavigate();

  const logOutHandler = async () => {
    await logOut();
    clearUserStorageData();
    resetUserData();

    navigate("/login/");
  };

  return (
    <div className="dashboard-header">
      <a href="/" className="dashboard-header_user-info">
        <img src={CSLogoImg} alt="coinstats logo" />
      </a>
      <div className="dashboard-header_right-sidebar">
        {user && (
          <>
            <Link to={"/profile"}>
              <Avatar src={user.imageUrl ? user.imageUrl : DefaultAvatarImg} />
            </Link>
          </>
        )}
        <Button xs={100} onClick={logOutHandler} className="logout-button">
          Log Out
        </Button>
        <Button
          className="hamburger-menu"
          type="primary"
          icon={<MenuOutlined />}
          onClick={() => menuOpenHandler(true)}
        />
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => menuOpenHandler()}
          open={isMenuOpen}
        >
          {children}
        </Drawer>
      </div>
    </div>
  );
};
export default Header;
