import React, { useCallback, useEffect, useState } from "react";
import { Button, Typography, message } from "antd";
import { getPriceList, getSubscription } from "../../../api/pricing";
import { SUBSCRIPTION_PLANS } from "../../../enums/subsciptionPlans";
import getUserSubscriptionPlan from "../../../helpers/getUserSubscriptionPlan";
import { pricingSectionData } from "../../../data/pricingSectionData";
import { pricingDefaultData } from "../../../data/pricingDefaultData";
import { getLimit } from "../../../api/apiKey";
import "./index.scss";
import { Link, useSearchParams } from "react-router-dom";

const { Title, Text } = Typography;

const PricingSection = ({ user }) => {
  const [pricingState, setPricingState] = useState(pricingDefaultData);
  const [buying, setBuying] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getStripePricesList();

    async function getStripePricesList() {
      const priceListObj = await getPriceList();
      const creditLimitObj = await getLimit();

      setPricingState((prevState) => {
        return {
          ...prevState,
          creditList: creditLimitObj?.products,
          priceList: priceListObj,
        };
      });
    }

    if (searchParams && searchParams.has("session_id")) {
      message.success("Subscribed Successfully");
      setSearchParams("");
    }
  }, []);

  const handleBuyClick = async (planType) => {
    setBuying(true);
    const res = await getSubscription(
      pricingState.priceList[planType].monthly?.id
    );
    window.location.href = res.url;
    setBuying(false);
  };

  const formattedCredits = useCallback((credit) => {
    return credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const userSubscription = getUserSubscriptionPlan(user);
  const isIndividualPlan = userSubscription === SUBSCRIPTION_PLANS.individual;
  const isBusinessPlan = userSubscription === SUBSCRIPTION_PLANS.bussiness;
  const hasCredit = pricingState.creditList.length > 0;

  return (
    <>
      <Title level={2} className="section-name">
        Pricing
      </Title>
      <div className="pricing-list">
        <div className="plan">
          <div>
            <Text className="plan_type free">Free</Text>
            <div>
              {pricingSectionData.free.map((item) => (
                <p className="plan_credits-quantity" key={item.description}>
                  <span>
                    ・
                    {item.hasDescriptionQuantity &&
                      hasCredit &&
                      formattedCredits(pricingState.creditList[0].credits)}
                  </span>
                  {item.description}
                </p>
              ))}
            </div>
          </div>
          {!(isBusinessPlan || isIndividualPlan) && (
            <Button className="plan_button">
              <Link to="/">Try for Free</Link>
            </Button>
          )}
        </div>
        <div className="plan most-popular-plan">
          <div>
            <div className="most-popular">
              <Text>Most Popular</Text>
            </div>
            <Text className="plan_type individual">Individual</Text>
            <p className="plan_price">
              <span>${pricingState.priceList.standard.monthly?.price}</span>{" "}
              /month
            </p>
            {pricingSectionData.standard.map((item) => (
              <p className="plan_credits-quantity" key={item.description}>
                <span>
                  ・
                  {item.hasDescriptionQuantity &&
                    hasCredit &&
                    formattedCredits(pricingState.creditList[1].credits)}
                </span>
                {item.description}
              </p>
            ))}
          </div>
          {!(isBusinessPlan || isIndividualPlan) && (
            <Button
              loading={buying}
              disabled={buying}
              onClick={() => handleBuyClick("standard")}
              className="buy-btn"
            >
              Buy
            </Button>
          )}
          {isIndividualPlan && (
            <Button disabled block>
              Current Plan
            </Button>
          )}
        </div>
        <div className="plan">
          <div>
            <Text className="plan_type">Business</Text>
            <p className="plan_price">
              <span>${pricingState.priceList.premium.monthly?.price}</span>{" "}
              /month
            </p>
            {pricingSectionData.premium.map((item) => (
              <p className="plan_credits-quantity" key={item.description}>
                <span>
                  ・
                  {item.hasDescriptionQuantity &&
                    hasCredit &&
                    formattedCredits(pricingState.creditList[2].credits)}
                </span>
                {item.description}
              </p>
            ))}
          </div>
          {!isBusinessPlan && (
            <Button
              loading={buying}
              disabled={buying}
              onClick={() => handleBuyClick("premium")}
            >
              {isIndividualPlan ? "Upgrade" : "Buy"}
            </Button>
          )}
          {isBusinessPlan && (
            <Button disabled block>
              Current Plan
            </Button>
          )}
        </div>
        <div className="plan">
          <div>
            <Text className="plan_type">Custom</Text>
            <p className="plan_price">
              <span>-</span>/month
            </p>
            {pricingSectionData.custom.map((item) => (
              <p className="plan_credits-quantity" key={item.description}>
                <span>・ {item.descriptionQuantity}</span> {item.description}
              </p>
            ))}
          </div>
          <a
            href="https://help.coinstats.app/en/"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Contact us</Button>
          </a>
        </div>
      </div>
    </>
  );
};

export default PricingSection;
