import {
  eachDayOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  format,
  getISODay,
  setMonth,
  setYear,
} from "date-fns";
import { CHART_INTERVALS } from "../enums/chartIntervals";

/**
 * generateHourlyDateRange Helper
 * @description Function generates a sorted range of dates between a given start and end date, by given interval.
 *
 * @param {string} startDate - start date of selected Range
 * @param {string} endDate - end date of selected Range
 * @param {array} fetchedDates - fetched Dates range between startDate and endDate
 * @param {string} interval - selected interval: "1h", "1d", "1w", "1M", "1y"
 *
 * @returns { [{date: string, count: number}] }
 */

export function generateDateRangeByInterval(
  startDate,
  endDate,
  fetchedDates,
  interval
) {
  let dateRange = [];
  const slicedDates = fetchedDates.map((item) => ({
    date: item.date.slice(0, -6),
    count: item.count,
  }));

  let currentDate = new Date(startDate);
  let lastDate = new Date(endDate);

  switch (interval) {
    case CHART_INTERVALS.day:
      dateRange = eachDayMatchingRange(currentDate, lastDate);
      break;
    case CHART_INTERVALS.week:
      dateRange = eachWeekMatchingRange(currentDate, lastDate);
      break;
    case CHART_INTERVALS.month:
      dateRange = eachMonthMatchingRange(currentDate, lastDate);
      break;
    case CHART_INTERVALS.year:
      dateRange = eachYearMatchingDate(currentDate, lastDate);
  }

  return mergeArrayObjAndArrayDates(slicedDates, dateRange);
}

function mergeArrayObjAndArrayDates(arrayObj, arrayDates) {
  const mergedArray = [];
  let i = 0;
  let j = 0;

  while (i < arrayObj.length || j < arrayDates.length) {
    const arrayObjDate =
      i < arrayObj.length ? new Date(arrayObj[i].date).getTime() : Infinity;
    const arrayCurrDate =
      j < arrayDates.length ? new Date(arrayDates[j]).getTime() : Infinity;

    if (arrayObjDate < arrayCurrDate) {
      mergedArray.push(arrayObj[i]);
      i++;
    } else if (arrayObjDate > arrayCurrDate) {
      mergedArray.push({ date: arrayDates[j], count: 0 });
      j++;
    } else {
      mergedArray.push(arrayObj[i]);
      i++;
      j++;
    }
  }

  return mergedArray;
}

function eachDayMatchingRange(currentDate, lastDate) {
  const dates = eachDayOfInterval({ start: currentDate, end: lastDate });
  return dates.map((date) => format(date, "yyyy-MM-dd"));
}

function eachWeekMatchingRange(currentDate, lastDate) {
  let weekStartDate = getISODay(currentDate);
  if (weekStartDate === 7) {
    weekStartDate = 0;
  }

  const matchingRange = eachWeekOfInterval(
    {
      start: currentDate,
      end: lastDate,
    },
    { weekStartsOn: weekStartDate }
  );

  return matchingRange.map((date) => {
    return format(date, "yyyy-MM-dd");
  });
}

function eachMonthMatchingRange(currentDate, lastDate) {
  const matchingRange = eachDayOfInterval({
    start: currentDate,
    end: lastDate,
  });
  const formatDates = matchingRange.filter(
    (date) => date.getDate() === currentDate.getDate()
  );

  return formatDates.map((date) => format(date, "yyyy-MM-dd"));
}

function eachYearMatchingDate(currentDate, lastDate) {
  const targetMonth = currentDate.getMonth();
  const targetDayOfMonth = currentDate.getDate();

  const dates = eachYearOfInterval({ start: currentDate, end: lastDate }).map(
    (year) => setYear(setMonth(currentDate, targetMonth), year.getFullYear())
  );

  const filteredDates = dates.filter(
    (date) =>
      date.getMonth() === targetMonth &&
      date.getDate() === targetDayOfMonth &&
      date <= lastDate
  );

  return filteredDates.map((date) => format(date, "yyyy-MM-dd"));
}
