export const pricingSectionData = {
  free: [
    {
      description: " credits per/month",
      hasDescriptionQuantity: true,
    },
    {
      description: "5 rate limit/sec",
    },
    {
      description: "Wallet connect up to 15 chain",
    },
    {
      description: "10 years historical data",
    },
    {
      description: "Daily Crypto News feed",
    },
    {
      description: "Exchange/Pairs realtime data",
    },
    {
      description: "Priority email support",
    }
  ],
  standard: [
    {
      description: " credits per/month",
      hasDescriptionQuantity: true,
    },
    {
      description: "20 rate limit/sec",
    },
    {
      description: "Wallet connect up to 15 chain",
    },
    {
      description: "10 years historical data",
    },
    {
      description: "Daily Crypto News feed",
    },
    {
      description: "Exchange/Pairs realtime data",
    },
    {
      description: "Priority email support",
    },
  ],
  premium: [
    {
      description: " credits per/month",
      hasDescriptionQuantity: true,
    },
    {
      description: "50 rate limit/sec",
    },
    {
      description: "Wallet connect up to 15 chain",
    },
    {
      description: "10 years historical data",
    },
    {
      description: "Daily Crypto News feed",
    },
    {
      description: "Exchange/Pairs realtime data",
    },
    {
      description: "Priority email support",
    },
  ],
  custom: [
    {
      description: " credits per/month",
      descriptionQuantity: "Individual",
    },
    {
      description: "50 rate limit/sec",
    },
    {
      description: "Wallet connect up to 15 chain",
    },
    {
      description: "10 years historical data",
    },
    {
      description: "Daily Crypto News feed",
    },
    {
      description: "Exchange/Pairs realtime data",
    },
    {
      description: "Priority email support",
    },
  ],
};
