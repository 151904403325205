import React, { useEffect, useMemo, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { doesUserTokenExist } from "./helpers/doesUserTokenExist";
import { getUserData } from "./helpers/getUserData";
import DashboardPrivateRoute from "./router/DashboardPrivateRoute";
import AuthPrivateRoute from "./router/AuthPrivateRoute";

import Authentication from "./components/Authentication";
import Dashboard from "./components/Dashboard";
import EmailVerification from "./components/Authentication/EmailVerification";
import EmailVerify from "./components/Authentication/EmailVerify";

const DATE_FOR_NEW_USERS = new Date(2022, 5, 24);

function App() {
  const [state, setState] = useState({
    userData: null,
    isUserLoading: doesUserTokenExist(),
    userIsNotVerified: true,
  });

  async function getUser() {
    setState((prevState) => {
      return {
        ...prevState,
        isUserLoading: true,
      };
    });

    const userData = await getUserData();
    const isNewUser =
      new Date(userData?.createdAt).getTime() >=
      DATE_FOR_NEW_USERS.getTime();

    setState({
      userData,
      isUserLoading: false,
      userIsNotVerified: !userData?.emailVerified && isNewUser
    });
  }

  useEffect(() => {
    if (!doesUserTokenExist()) {
      return;
    }
    getUser();
  }, []);

  const loadingIcon = useMemo(() => {
    return (
      <LoadingOutlined
        style={{
          fontSize: 50,
        }}
        spin
      />
    );
  }, []);

  const resetUserData = () => {
    setState((prevState) => {
      return { ...prevState, userData: null };
    });
  };

  if (state.isUserLoading) {
    return <Spin indicator={loadingIcon} />;
  }


  return (
    <>
      <Routes>
        <Route
          path="/login/"
          element={
            <AuthPrivateRoute>
              <Authentication setUserData={setState} />
            </AuthPrivateRoute>
          }
        />
        <Route
          path="/email-verification"
          element={
            <EmailVerification
              setUserData={setState}
              userData={state.userData}
            />
          }
        />
        <Route path="/email-verify" element={<EmailVerify 
              getUser={getUser}
              />} />
        <Route
          path="/*"
          element={
            <DashboardPrivateRoute
              userIsNotVerified={state.userIsNotVerified}
              userData={state.userData}
              resetUserData={resetUserData}
            >
              <Dashboard userData={state.userData} />
            </DashboardPrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
