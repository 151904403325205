import React, { useCallback, useEffect, useState } from "react";
import { Button, message, Spin, Typography } from "antd";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { SUBSCRIPTION_PLANS } from "../../../enums/subsciptionPlans";
import { SUBSCRIPTION_TYPES } from "../../../enums/subscriptionTypes";
import getUserSubscriptionPlan from "../../../helpers/getUserSubscriptionPlan";
import {
  deleteApiKey,
  generateApiKey,
  getApiKey,
  getLimit,
} from "../../../api/apiKey";
import GradientProgressBar from "../../GradientProgressBar";

import CopyIcon from "../../../assets/images/copy-icon.svg";
import "./index.scss";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;
const { confirm } = Modal;

function getCurrentSubscriptionType(user) {
  const userSubscriptionPlan = getUserSubscriptionPlan(user);
  if (userSubscriptionPlan === SUBSCRIPTION_PLANS.individual) {
    return SUBSCRIPTION_TYPES.standard;
  }

  if (userSubscriptionPlan === SUBSCRIPTION_PLANS.bussiness) {
    return SUBSCRIPTION_TYPES.business;
  }

  return SUBSCRIPTION_TYPES.free;
}

const DashboardSection = ({ user }) => {
  const [state, setState] = useState({
    apiKey: null,
    userId: null,
    isGenerating: false,
    isDeleting: false,
    isLoading: false,
    balance: 0,
    currentCreditObj: {},
  });

  useEffect(() => {
    if (state.apiKey) {
      localStorage.setItem("apiKey", state.apiKey);
      return;
    }

    getUserApiKey();

    async function getUserApiKey() {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));

      const apiKeyObj = await getApiKey();
      const creditLimitObj = await getLimit();

      let currentCreditObj = {};

      if (creditLimitObj.products && user) {
        currentCreditObj = creditLimitObj.products?.find(
          (plan) => plan.name === getCurrentSubscriptionType(user)
        );
      }

      setState((prevState) => ({
        ...prevState,
        currentCreditObj,
        apiKey: apiKeyObj?.apiKey,
        userId: apiKeyObj?.userId,
        balance: apiKeyObj?.balance || prevState.balance,
        isLoading: false,
      }));
    }
  }, [state.apiKey, user]);

  const generateApiKeyHandler = async () => {
    setState((prevState) => ({
      ...prevState,
      isGenerating: true,
    }));

    const res = await generateApiKey();

    if (res.error) {
      message.error(res.error);

      setState((prevState) => ({
        ...prevState,
        isGenerating: false,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      apiKey: res.apiKey,
      isGenerating: false,
    }));
    message.success("Token Generated successfully!");
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are you sure you want to delete this API key?",
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteApiKeyHandler();
      },
    });
  };

  const deleteApiKeyHandler = async () => {
    localStorage.removeItem("apiKey");
    setState((prevState) => ({
      ...prevState,
      isDeleting: true,
    }));
    const res = await deleteApiKey();

    if (res.error) {
      message.error(res.error);

      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      apiKey: null,
      userId: null,
      isDeleting: false,
    }));

    message.success("Key Deleted Successfully");
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText(state.apiKey);
    message.success("Copied Successfully");
  };

  const formattedCredits = useCallback((credit) => {
    return (credit || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const creditLimitByPercent =
    (state.balance / state.currentCreditObj?.credits) * 100;
  return (
    <>
      {state.isLoading && <Spin />}
      {!state.isLoading && (
        <div className="dashboard">
          <Title level={2} className="section-name">
            Dashboard
          </Title>
          <div className="dashboard_balance-section">
            <div className="balance-progress">
              <Text className="balance">
                <span>{formattedCredits(state.balance)}</span>/
                {formattedCredits(state.currentCreditObj?.credits)}
              </Text>
              <Button className="upgrade-btn">
                <Link to={"/pricing"}>Upgrade</Link>
              </Button>
            </div>
            <GradientProgressBar
              percent={creditLimitByPercent}
              showInfo={false}
            />
            <Text className="secondary-text">
              Your monthly API credit limit
            </Text>
            <div className="action-buttons">
              {!state.apiKey && (
                <Button
                  type="primary"
                  onClick={generateApiKeyHandler}
                  loading={state.isGenerating}
                  className="generate-button"
                >
                  Generate API key
                </Button>
              )}
              {state.apiKey && (
                <>
                  <div className="apikey-section">
                    <Text>{state.apiKey}</Text>
                    <img
                      src={CopyIcon}
                      onClick={handleCopy}
                      alt="copy icon"
                      className="copy-icon"
                    />
                  </div>
                  <Button
                    type="primary"
                    onClick={showDeleteConfirm}
                    loading={state.isDeleting}
                    className="delete-button"
                    disabled={state.isDeleting}
                    danger
                  >
                    Delete API key
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardSection;
