import { Typography } from "antd";
import "./index.scss";

const { Text } = Typography;

const Footer = () => {
  return (
    <Text type="secondary" className="footer">
      © {new Date().getFullYear()} CoinStats. All rights reserved
    </Text>
  );
};
export default Footer;
