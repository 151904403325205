import { useState } from "react";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Footer from "../Footer";

import "./index.scss";

const Authentication = ({ setUserData }) => {
  const [isSignInSectionVisible, setIsSignInSectionVisible] = useState(true);
  const handleAuthTypeClick = () => {
    setIsSignInSectionVisible((prevState) => !prevState);
  };

  return (
    <>
      <div className="authentication-wrapper">
        {isSignInSectionVisible && (
          <SignIn
            handleAuthTypeClick={handleAuthTypeClick}
            setUserData={setUserData}
          />
        )}
        {!isSignInSectionVisible && (
          <SignUp handleAuthTypeClick={handleAuthTypeClick} />
        )}
      </div>
      <Footer />
    </>
  );
};
export default Authentication;
