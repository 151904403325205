import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Result, Spin, message } from "antd";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";

import { verifyEmail } from "../../../api/auth";

import "./index.scss";

const EmailVerify = ({ getUser }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoadingSection, setIsLoadingSection] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("t");

    if (!token) {
      message.error("Something went wrong");
      return;
    }
    verifyUserToken(token);

    async function verifyUserToken(token) {
      setIsLoadingSection(true);
      const res = await verifyEmail(token);
      setIsLoadingSection(false);

      if (res.error) {
        message.error(res.error);
        return;
      }

      if (res.data?.success) {
        message.success("Email Verified");
        getUser();
        navigate("/");
        return;
      }

      message.error("Something went wrong");
    }
  }, [getUser, location.search, navigate]);

  const resendHandler = async () => {
    navigate("/login");
  };

  const loadingIcon = useMemo(() => {
    return (
      <LoadingOutlined
        style={{
          fontSize: 50,
        }}
        spin
      />
    );
  }, []);

  return (
    <>
      {isLoadingSection && <Spin indicator={loadingIcon} />}
      {!isLoadingSection && (
        <Result
          icon={<MailOutlined />}
          extra={[
            <Button key="resend" type="primary" onClick={resendHandler}>
              Back Home
            </Button>,
          ]}
        />
      )}
    </>
  );
};
export default EmailVerify;
