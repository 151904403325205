import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { doesUserTokenExist } from "../helpers/doesUserTokenExist";
import { Layout } from "antd";
import Sider from "antd/es/layout/Sider";
import SideBarMenu from "../components/Dashboard/SideBarMenu";
import Header from "../components/Dashboard/Header";

const DashboardPrivateRoute = ({
  userIsNotVerified,
  children,
  userData,
  resetUserData,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuOpenHandler = (isOpen = false) => {
    setIsMenuOpen(isOpen);
  };

  if (!doesUserTokenExist()) {
    return <Navigate to="/login/" />;
  }

  if (userIsNotVerified) {
    return <Navigate to="/email-verification/" />;
  }

  return (
    <>
      <Header
        user={userData}
        isMenuOpen={isMenuOpen}
        menuOpenHandler={menuOpenHandler}
        resetUserData={resetUserData}
      >
        <SideBarMenu resetUserData={resetUserData} />
      </Header>
      <div className="dashboard-content">
        <Sider width={200}>
          <SideBarMenu resetUserData={resetUserData} />
        </Sider>
        <Layout>{children}</Layout>
      </div>
    </>
  );
};

export default DashboardPrivateRoute;
