import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message, Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import { signUp } from "../../../api/auth";
import {
  getGrecaptchaToken,
  useRecaptchaToken,
} from "../../../Hooks/useRecaptchaToken";
import RECAPTCHA_ACTIONS from "../../../enums/RecaptchaActions";
import GeeTestV4 from "../../GeeTestV4";

import CSLogoImg from "../../../assets/images/cs-logo.svg";

const { Title, Text } = Typography;

const SignUp = ({ handleAuthTypeClick }) => {
  const [state, setState] = useState({
    email: "",
    password: "",
    errorMessage: "",
    recaptchaAssessmentId: "",
    isCaptchaPopupOpen: false,
    isLoading: false,
  });

  const [form] = useForm();
  const navigate = useNavigate();
  useRecaptchaToken();

  const onSuccess = async ({ geeTest, recaptchaToken, authData } = {}) => {
    setState((prevState) => {
      return {
        ...prevState,
        errorMessage: "",
        isLoading: true,
      };
    });

    let reqBody = {
      email: state.email,
      password: state.password,
      geeTest: {
        ...geeTest,
        recaptchaAssessmentId: state.recaptchaAssessmentId,
      },
    };
    if (!geeTest) {
      reqBody.captchaToken = recaptchaToken;
    }
    if (authData) {
      reqBody.email = authData.email;
      reqBody.password = authData.password;
    }

    try {
      const signupData = await signUp(reqBody);
      if (signupData.recaptchaAssessmentId) {
        setState((prevState) => {
          return {
            ...prevState,
            errorMessage: "",
            isCaptchaPopupOpen: true,
            recaptchaAssessmentId: signupData.recaptchaAssessmentId,
          };
        });
        return;
      }

      if (signupData.error) {
        setState((prevState) => {
          return {
            ...prevState,
            errorMessage: signupData.error,
            isCaptchaPopupOpen: false,
            isLoading: false,
          };
        });
        return;
      }

      if (signupData.token) {
        const userDataBase = btoa(signupData.token);
        message.success("Form submitted successfully!");
        localStorage.setItem("token", userDataBase);
        navigate("/email-verification");
      }

      setState((prevState) => {
        return { ...prevState, isLoading: false, isCaptchaPopupOpen: false };
      });
    } catch (err) {
      setState((prevState) => {
        return {
          ...prevState,
          errorMessage: "Invalid email or password",
          isCaptchaPopupOpen: false,
          isLoading: false,
        };
      });
    }
  };

  const onFormSubmit = async (authData) => {
    if (state.isLoading) {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        isLoading: true,
        errorMessage: "",
        email: authData.email,
        password: authData.password,
      };
    });
    localStorage.removeItem("token");

    const recaptchaToken = await getGrecaptchaToken(
      RECAPTCHA_ACTIONS.email_signup
    );
    if (recaptchaToken) {
      await onSuccess({ recaptchaToken, authData });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          isCaptchaPopupOpen: true,
        };
      });
    }
  };

  const onCaptchaFail = () => {
    message.error("Captcha submission failed");
    setState((prevState) => {
      return {
        ...prevState,
        isCaptchaPopupOpen: false,
        isLoading: false,
        errorMessage: "",
      };
    });
  };

  return (
    <div>
      {state.errorMessage && message.error(state.errorMessage)}
      {state.isCaptchaPopupOpen && (
        <GeeTestV4
          onSuccess={onSuccess}
          onClose={onCaptchaFail}
          onError={onCaptchaFail}
          isDestroy={state.errorMessage}
          forceShow={state.isCaptchaPopupOpen}
        />
      )}
      <img src={CSLogoImg} alt="coinstats logo" />
      <Title level={2} className="title">
        Welcome!
      </Title>
      <Text type="secondary" className="secondary-text">
        Register your account.
      </Text>
      <Form
        name="registration"
        className="form"
        form={form}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: '"email" must be a valid email',
            },
            {
              required: true,
              message: "Email is required",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Sign Up With Email"
            autoComplete="on"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="Create Password"
            autoComplete="on"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="form-button"
            loading={state.isLoading}
          >
            Create Account
          </Button>
          <div className="registration-section">
            <Text type="secondary" className="secondary-text">
              Already have an account?
            </Text>
            <a onClick={handleAuthTypeClick}>Login</a>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignUp;
