import { doesUserTokenExist } from "../helpers/doesUserTokenExist";
import {isBase64} from "../helpers/isBase64";

export const ApiUrls = {
  prod: process.env.REACT_APP_API_URL,
  staging: process.env.REACT_APP_STAGING_API_URL,
  dev2: process.env.REACT_APP_DEV2_API_URL,
};

export const API_URL = ApiUrls.prod;

export const config = {
  APP_URL_V2: `${API_URL}/v2`,
  APP_URL_V3: `${API_URL}/v3`,
  APP_URL_V4: `${API_URL}/v4`,
  googleCaptchaSiteKey: "6LdI4gkjAAAAABy1WuhhxXrCY9CWbjUCTXsLOmNL",
  googleCaptchaSiteKeyTest: "6LfBO0gkAAAAALy5e9jVVlIsoisvjSywQIWOlWq3",
};

export const getAxiosHeaders = (headers) => {
  const userToken = localStorage.getItem("token");
  let headersObj = { headers: { platform: "web", ...headers } };

  if (doesUserTokenExist()) {
    headersObj.headers.token = isBase64(userToken)? atob(userToken) : userToken;
  }

  return headersObj;
};
