import axios from "axios";
import { config, getAxiosHeaders } from "./ApiConfig";

export const getPriceList = async () => {
  try {
    const res = await axios.get(
      config.APP_URL_V3 + "/subscriptions/public_api/plans",
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return null;
  }
};

export const getSubscription = async (subscriptionType) => {
  try {
    const dataToSend = {
      priceData: {
        line_items: {
          price: subscriptionType,
          quantity: 1,
        },
      },
    };
    const res = await axios.post(
      config.APP_URL_V3 + "/subscriptions/public_api/session",
      dataToSend,
      getAxiosHeaders()
    );

    return res.data;
  } catch (e) {
    return null;
  }
};

export const getManagementUrl = async () => {
  try {
    const res = await axios.get(
      config.APP_URL_V3 + "/subscriptions/public_api/stripe/management_url",
      getAxiosHeaders()
    );
    return res.data;
  } catch (e) {
    return null;
  }
};
