import React, { useState } from "react";
import { Card, Menu, Typography } from "antd";
import { menuData } from "../../../data/menuDefaultData";
import HistoryTable from "./HistoryTable";
import HistoryChart from "./HistoryChart";

import "./index.scss";

const { Title } = Typography;

const DEFAULT_ACTIVE_SECTION = "chart";

const HistorySection = () => {
  const [sectionType, setSectionType] = useState(DEFAULT_ACTIVE_SECTION);

  return (
    <>
      <Title level={2} className="section-name">
        History
      </Title>
      <Card>
        <Menu
          onClick={(e) => setSectionType(e.key)}
          selectedKeys={[sectionType]}
          mode="horizontal"
          items={menuData}
        />
        {!sectionType && <HistoryTable />}
        {sectionType && <HistoryChart />}
      </Card>
    </>
  );
};
export default HistorySection;
