import { SUBSCRIPTION_PLANS } from "../enums/subsciptionPlans";

export default function getUserSubscriptionPlan(user) {
  if (user?.subscription?.publicApiPlanId === SUBSCRIPTION_PLANS.individual) {
    return SUBSCRIPTION_PLANS.individual;
  }

  if (user?.subscription?.publicApiPlanId === SUBSCRIPTION_PLANS.bussiness) {
    return SUBSCRIPTION_PLANS.bussiness;
  }

  return "";
}
